export const Logo = () => {
  return (
    <svg
      width="64px"
      height="64px"
      version="1.0"
      viewBox="0 0 145 153"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <mask id="mask">
          <rect width="100%" height="100%" fill="white" />
          <g transform="translate(0 153) scale(.1 -.1)" fill="black">
            <path d="m0 765v-765h725 725v765 765h-725-725v-765zm577 286 388-300 3 300 2 299h45 45l2-336 3-336 43-36 42-35v372 371h45 45v-461-460l-27 19c-16 11-233 178-483 371l-455 351-3-485-2-485h-45-45v575c0 316 2 575 4 575s179-135 393-299zm237-405 426-330v-53c0-29-2-53-5-53-4 0-310 235-680 521l-100 78-3-304-2-305h-45-45v395c0 333 2 395 14 388 7-4 205-156 440-337z" />
          </g>
        </mask>
      </defs>
      {/*<rect width="100%" height="100%" fill="#388E3C" mask="url(#mask)" />*/}
      <rect width="100%" height="100%" fill="#44d62c" mask="url(#mask)" />
    </svg>
  );
};
