import { NavLink } from 'react-router-dom';

import { Logo } from 'src/components/logo';

import styles from './app-navigation.module.scss';

export const AppNavigation = () => {
  return (
    <div className={styles.navigation}>
      <div className={styles.navigationBar}>
        <div className={styles.logo}>
          <NavLink to="/">
            <div className={styles.mainLogo}>
              <Logo />

              <div className={styles.mainLogo__textWrapper}>
                <div>
                  <span className={`encode-sans-expanded-semibold ${styles.logoTitle}`}>NESTOR</span>
                  &nbsp;&nbsp;
                  <span className="encode-sans-expanded-light">STUDIO</span>
                </div>
                <div className={`encode-sans-expanded-medium ${styles.logoSubtitle}`}>Making life easier</div>
              </div>
            </div>
          </NavLink>
        </div>

        <nav className={styles.nav}>
          <ul>
            <li>
              <NavLink to="/infosaver">Info Saver</NavLink>
            </li>
            <li>
              <NavLink to="/sfq">Smoke-Free Quest</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
